export const USER_CONFIG_MESSAGES = {
  CREATE_USER_SUCCESS: 'User has been successfully created!',
  CREATE_USER_ERROR: 'Failed to create the user',
  CREATE_ROLE_SUCCESS: 'Role has been successfully created!',
  CREATE_ROLE_ERROR: 'Failed to create the role',

  UPDATE_USER_SUCCESS: 'User has been successfully updated!',
  UPDATE_USER_ERROR: 'Failed to update the user',
  UPDATE_ROLE_SUCCESS: 'Role has been successfully updated!',
  UPDATE_ROLE_ERROR: 'Failed to update the role',

  DELETE_USER_SUCCESS: 'User has been successfully deleted!',
  DELETE_USER_ERROR: 'Failed to delete the user',
  DELETE_ROLE_SUCCESS: 'Role has been successfully deleted!',
  DELETE_ROLE_ERROR: 'Failed to delete the role',

  DELETE_USER_DESCRIPTION_1:
    '	Deleting User will remove the permissions for associated user',
  DELETE_USER_DESCRIPTION_2: 'Do you wish to delete user -',
  DELETE_ROLE_DESCRIPTION_1:
    'Deleting this Role will make the system inaccessible to any users with only this assigned role',
  DELETE_ROLE_DESCRIPTION_2: 'Do you wish to delete role -',

  UPDATE_USER_DESCRIPTION_1: 'Do you wish to update user?',
  UPDATE_ROLE_DESCRIPTION_1: 'Do you wish to update role?',

  EMPTY_SEARCH: 'Enter a valid string to search',
  SEARCH_SUCCESS: 'Found users with matching names !',
  NO_USER_FOUND: 'No user found !',
  TOOLTIP_TEXT: [
    `Please review the definitions for the permissions:`,
    [`a. `, ` View Only: `, `The user will be able to only view only day to day screen`],
    [
      `b. `,
      ` Edit Only: `,
      `The user will be able to edit data on the Day to day screen`,
    ],
    [
      `c. `,
      ` Edit and Configuration: `,
      ` The user will be able to configure and edit data on the day to day screens`,
    ],
    [
      `d. `,
      ` System Admin: `,
      ` The user will be able to perform all of the above permissions and will be able to access the user access configuration`,
    ],
  ],
  INVALID_MESSAGE_CHECK: 'Invalid end date !',
  ERROR_MESSAGE: [
    'Please select a valid option!',
    'Invalid Date, end date lesser than start date!',
    'Invalid Date! The Role Assignment Start Date cannot be lesser than the User Access Start Date!',
    'Invalid Date! The Role Assignment End Date cannot be greater than the User Access End Date!',
    'The entered date must be equal to or greater than today',
  ],
  SEARCH_ROLE: 'Search Roles..',
  SEARCH_USER: 'Search Users..',

  'Toast.message.SUCCESS': 'Your changes were saved Successfully!',
  'Toast.message.ERROR': 'Oops, something went wrong. Please try again later!',
  'Toast.message.WARNING': 'Oops, something went wrong!',
  'Toast.message.INFO': 'Please wait',
};

export const queryConstants = {
  GET_ROLE_DETAILS: 'getRoleDetails',
  GET_USER_DETAILS: 'fetchUserDetails',
  GET_USER_CONFIG_DROPDOWNS: 'getUsersDropdown',
  GET_ROLE_CONFIG_DROPDOWNS: 'getRolesDropdown',
  GET_ROLE_DETAILS_BY_ID: 'fetchRoleDetailsById',
  GET_USER_DETAILS_BY_ID: 'fetchUserDetailsById',
};
