// Library Imports
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import  AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import  AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { useForm } from 'react-hook-form';
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  Typography,
  Fade,
  Modal,
  Backdrop,
  IconButton,
  styled,
} from '@mui/material';

import GeneralButton from '../../../components/Button';

// File Imports
import {
  ROLE_CONFIGURATION_CONSTANTS,
  ACCESS_CONFIGURATION_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
  MAX_INPUT_LENGTH,
  RBAC_CONSTANTS,
  BUTTON_TYPE,
} from '../../../constants';
import {
  createRole,
  updateRole,
  deleteRoleFromConfig,
  getRoleDetails,
  getRoleDropDown,
  getRoleDetailsById,
} from '../../../utils/apiHelper';
import  AddButton  from '../../../assets/img/PlusInCircle.svg';
import DeleteExclamation  from '../../../assets/img/blueExclamation.svg';
import DeleteButton  from '../../../assets/img/deleteBin.svg';
import SearchIcon  from '../../../assets/img/searchIcon.svg';
import { queryConstants, USER_CONFIG_MESSAGES } from '../../../constants/en-us';

import {
  InputBoxGenerator,
  TableCellGenerator,
  INITIAL_TABLE_STATE,
} from '../../../modules/roleConfig/roleConfigModules';
import { escapeRegex } from '../../../modules/regex/regexRoles';
import { useToastContext } from '../../../context/toastContext';
import { useRxjsState } from '../../../utils/hooks/useRxjsState';
import { ModalPaper, SearchBoxGrid, SearchIconSpan, SectionGrid, StyledButtonContainer, StyledIconButton, StyledTableRow } from '../accessConfig';

// Styled Components
const TableGridContainer = styled(Grid)(({ theme }) => {
  return {
    border: `1px solid ${theme.customColors.plantTableInnerRowGray}`,
    width: 'calc(100% + 2em)',
    maxWidth: 'calc(100% + 2em)',
    marginTop: '-0.063em',
    minHeight: 'calc(100% - 25.750em)',
    padding: '1.25em',
    overflowX: 'auto',
    '& tr.MuiTableRow-root th:last-child': {
      minWidth: '15.375em',
      display: 'flex',
      alignItems: 'center',
    },
  };
});

const RoleConfigContainer = () => {
  const { toastDispatch } = useToastContext();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue: setFormValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
  });

  const { rxjsState } = useRxjsState();
  const [userRolesList, setUserRolesList] = useState([]);
  const [tableData, setTableData] = useState(INITIAL_TABLE_STATE);
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const queryClient = useQueryClient();
  const [levelMap, setLevelMap] = useState({});
  const [isInputChanged, setIsInputChanged] = useState(false);
  const [isUpdateInputChanged, setIsUpdateInputChanged] = useState(false);
  const [isTableChanged, setIsTableChanged] = useState(false);

  /**
   * Resetting the form values on mounting this component
   */
  useEffect(() => {
    reset({
      roleName: '',
      businessFunction: null,
      description: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormValue('associatedRoles', tableData);
  }, [tableData, setFormValue]);

  // Is Dirty Check : Upper fields
  useEffect(() => {
    const [roleName, description, businessFunction] = watch([
      'roleName',
      'description',
      'businessFunction',
    ]);
    if (isEdit && searchResult) {
      let isUpdateFieldsUpdated = false;
      if (
        roleName != searchResult.roleName ||
        description != searchResult.description ||
        businessFunction != searchResult.businessFunction
      ) {
        isUpdateFieldsUpdated = true;
      }
      setIsUpdateInputChanged(isUpdateFieldsUpdated);
    } else {
      let isInputFieldsUpdated = false;
      if (roleName != '' || description != '' || businessFunction != null) {
        isInputFieldsUpdated = true;
      }
      setIsInputChanged(isInputFieldsUpdated);
    }
  }, watch(['roleName', 'description', 'businessFunction']));

  // Is Dirty Check : Permission Table
  useEffect(() => {
    checkTableChanges();
  }, [tableData]);

  const checkTableChanges = () => {
    let roleMap = {};
    if (searchResult) {
      searchResult.associatedRoles
        .filter((row) => row.roleId)
        .forEach((role) => {
          roleMap[role.roleId] = role;
        });
    }
    if (tableData) {
      // logic to check Table field changes
      let isTableHaveChanges = false;
      tableData.forEach((role) => {
        // For Delete
        if (role.isDeleted) {
          isTableHaveChanges = true;
        } else if (role.roleId) {
          // For Update
          let prevResult = roleMap[role.roleId];
          if (prevResult) {
            const isModified =
              role.level != prevResult.level ||
              role.landingPage != prevResult.landingPage ||
              role.permission != prevResult.permissions ||
              role.businessUnit != prevResult.subLevel;
            if (isModified) {
              isTableHaveChanges = true;
            }
          }
        } else {
          // For Create
          if (
            role.level != null ||
            role.landingPage != null ||
            role.permission != null ||
            role.businessUnit != null
          ) {
            isTableHaveChanges = true;
          }
        }
      });
      setIsTableChanged(isTableHaveChanges);
    }
  };

  // Disable Save/Update Button
  const disableActionButton =
    (isEdit && !(isTableChanged || isUpdateInputChanged)) || // disable update button
    (!isEdit && !(isTableChanged || isInputChanged)); // disable save button

  // On Reset Fucntion
  const onReset = (
    searchRoleAPIInProgress,
    roleName,
    businessFunction,
    description,
    roleId,
    isOutModified
  ) => {
    reset({
      roleId: roleId || null,
      roleName: roleName || '',
      businessFunction: businessFunction || null,
      description: description || '',
      isOutModified: isOutModified || false,
    });
    setTableData(INITIAL_TABLE_STATE);
    if (isEdit) {
      setIsEdit(false);
      setSelectedRoleId(null);
      setIsTableChanged(false);
      setIsUpdateInputChanged(false);
    } else {
      setIsInputChanged(false);
    }
    if (isSubmitButtonClicked) {
      setIsSubmitButtonClicked(false);
    }
    if (searchRoleAPIInProgress) {
      queryClient.invalidateQueries(queryConstants.GET_ROLE_DETAILS);
    }
  };

  // AutoSuggestion : Search List
  useQuery([queryConstants.GET_ROLE_DETAILS], async () => {
    const data = await getRoleDetails();
    setUserRolesList(data);
  });

  // Toast
  const enableToast = (type, message) => {
    toastDispatch({ type, payload: { message } });
  };

  // DropDown Data Formating
  const formatDataToOldStructure = (dummyDropdown) => {
    return {
      plant: dummyDropdown.plants.map((item) => ({
        id: item.entityId,
        configDisplayValue: item.entityName,
      })),
      businessUnit: dummyDropdown.businessUnits.map((item) => ({
        id: item.businessUnitId,
        configDisplayValue: item.businessUnitName,
      })),
      level: dummyDropdown.rolesConfig.level,
      LandingPage: dummyDropdown.rolesConfig.landingPage,
      permission: dummyDropdown.rolesConfig.permission,
      businessFunctions: dummyDropdown.rolesConfig.businessFunctions,
    };
  };

  
  // Query : Get RoleDropDownData and structure
  const { data: rolesDropDownData } = useQuery(
    [queryConstants.GET_ROLE_CONFIG_DROPDOWNS],
    async () => {
      const updateData = await getRoleDropDown();
      const rolesConfig = updateData?.rolesConfig;

      // Creating Level Map
      const levelMapTemp = {};
      rolesConfig?.level.forEach((level) => {
        levelMapTemp[level.configValue] = level;
      });
      setLevelMap(levelMapTemp);
      return formatDataToOldStructure(updateData);
    }
  );

  // AutoSuggestion : Functions : START
  const getSuggestionValue = (suggestion) => suggestion.roleName;
  const escapeRegexCharacters = (str) => str.replace(escapeRegex, '\\$&');

  const getSuggestions = (suggestionValue) => {
    const escapedValue = escapeRegexCharacters(suggestionValue.trim());
    if (escapedValue === '') {
      return [];
    }
    const regex = new RegExp(`^${escapedValue}`, 'i');
    return userRolesList.filter((roleList) => regex.test(roleList.roleName));
  };

  const renderSuggestion = (suggestion, { query }) => {
    const matches = AutosuggestHighlightMatch(suggestion.roleName, query);
    const parts = AutosuggestHighlightParse(suggestion.roleName, matches);
    return (
      <span>
        {parts.map((part, index) => {
          const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;
          return (
            <span className={className} key={`${index + part.text}`} data-testid={`${index + part.text}`}>
              {part.text}
            </span>
          );
        })}
      </span>
    );
  };

  const onChange = (_event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = (data) => {
    setSuggestions(getSuggestions(data.value));
  };
  // AutoSuggestion : Functions : END

  // Set Data after Search
  const setRoleDataIntoFormAndTable = (userData) => {
    const globalLevelId = levelMap[ROLE_CONFIGURATION_CONSTANTS.GLOBAL]?.id;
    const tableDataToInsert = userData.associatedRoles.map((role, index) => ({
      roleId: userData.roleId,
      rowID: index,
      roleName: userData.roleName,
      permission: role.permissions,
      landingPage: role.landingPage,
      level: role.level,
      businessUnit: role.subLevel,
      disabled: role.level === globalLevelId,
      isCreated: false,
      isModified: false,
      isDeleted: false,
    }));

    onReset(
      null,
      userData.roleName,
      userData.businessFunction,
      userData.description,
      userData.roleId,
      false
    );

    setIsEdit(true);
    setTableData(tableDataToInsert);
  };

  // Query : After Search Selection
  const { isFetching: isgetRoleDetailsByIdFetching } = useQuery(
    [`${queryConstants.GET_ROLE_DETAILS_BY_ID}${selectedRoleId}`],
    async () => {
      return getRoleDetailsById(selectedRoleId);
    },
    {
      enabled: !!selectedRoleId,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        const subLevelId =
          data.level.id === levelMap[ROLE_CONFIGURATION_CONSTANTS.BU]?.id
            ? data.businessUnitId
            : data.level.id === levelMap[ROLE_CONFIGURATION_CONSTANTS.PLANT]?.id
            ? data.plantId
            : '';
        const modifiedResponse = {
          roleId: data.roleId,
          roleName: data.roleName,
          description: data.description || '',
          businessFunction: data.businessFunction.id,
          isOutModified: false,
          associatedRoles: [
            {
              level: data.level.id,
              subLevel: subLevelId,
              permissions: data.permission.id,
              landingPage: data.landingPage.id,
              roleId: data.roleId,
            },
          ],
        };
        setValue('');
        setSearchResult(modifiedResponse);
        setRoleDataIntoFormAndTable(modifiedResponse);
      },
      // OnERROR
    }
  );

  const onSuggestionSelected = (
    _event,
    // Extra params we can get from above method suggestionValue, suggestionIndex, sectionIndex, method
    { suggestion }
  ) => {
    if (suggestion && suggestion.roleId) {
      setSelectedRoleId(suggestion.roleId);
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputPropsAutoSuggest = {
    placeholder: USER_CONFIG_MESSAGES.SEARCH_ROLE,
    maxLength: MAX_INPUT_LENGTH,
    value,
    onChange,
  };

  // Create Role
  const { mutate: createRoleMutate, isLoading: iscreateRoleLoading } = useMutation(
    (data) => createRole(data),
    {
      onSuccess: () => {
        onReset(true);
        setIsSubmitButtonClicked(false);
        enableToast(
          TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          USER_CONFIG_MESSAGES.CREATE_ROLE_SUCCESS
        );
      },
      onError: (error) => {
        setIsSubmitButtonClicked(false);
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          enableToast(TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST, errorMessage);
        } else {
          enableToast(
            TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            USER_CONFIG_MESSAGES.CREATE_ROLE_ERROR
          );
        }
      },
    }
  );

  // Update role
  const { mutate: updateRoleMutate, isLoading: isupdateRoleLoading } = useMutation(
    (data) => updateRole(data),
    {
      onSuccess: () => {
        setIsEdit(false);
        onReset(true);
        setIsSubmitButtonClicked(false);
        setSelectedRoleId(null);
        setSearchResult(null);
        enableToast(
          TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          USER_CONFIG_MESSAGES.UPDATE_ROLE_SUCCESS
        );
      },
      onError: (error) => {
        setIsSubmitButtonClicked(false);

        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          enableToast(TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST, errorMessage);
        } else {
          enableToast(
            TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            USER_CONFIG_MESSAGES.UPDATE_ROLE_ERROR
          );
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  // Delete role
  const { mutate: deleteRoleMutate, isLoading: isdeleteRoleLoading } = useMutation(
    (roleId) => deleteRoleFromConfig(roleId),
    {
      onSuccess: () => {
        setIsEdit(false);
        onReset(true);
        setModalOpen(false);
        enableToast(
          TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          USER_CONFIG_MESSAGES.DELETE_ROLE_SUCCESS
        );
      },
      onError: (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          enableToast(TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST, errorMessage);
        } else {
          enableToast(
            TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            USER_CONFIG_MESSAGES.DELETE_ROLE_ERROR
          );
        }
        setModalOpen(false);
      },
    }
  );

  // Set DropDrown Options
  const setOptions = (index, row) => {
    let secondDropdownOptions = [];

    const isBUSystemAdmin = rxjsState.userData?.groups.includes(
      RBAC_CONSTANTS.ADMIN_ROLES.BUSINESS_UNIT
    );

    if (row && rolesDropDownData) {
      switch (row.level) {
        case levelMap[ROLE_CONFIGURATION_CONSTANTS.PLANT]?.id: // Plant
          secondDropdownOptions = rolesDropDownData.plant;
          if (!!rxjsState.factoryId) {
            secondDropdownOptions = secondDropdownOptions.filter(
              (plant) => plant.id === rxjsState.factoryId
            );
          }

          break;
        case levelMap[ROLE_CONFIGURATION_CONSTANTS.BU]?.id: // Business Group
          secondDropdownOptions = rolesDropDownData.businessUnit;
          break;
        default:
          secondDropdownOptions = [];
      }
    }
    switch (index) {
      case 0:
        if (rolesDropDownData && !!rxjsState.factoryId) {
          return rolesDropDownData.level.filter(
            (level) =>
              level.configValue.toLowerCase() ===
              ROLE_CONFIGURATION_CONSTANTS.PLANT.toLowerCase()
          );
        }

        if (rolesDropDownData && isBUSystemAdmin && !rxjsState.factoryId) {
          return rolesDropDownData.level.filter(
            (level) =>
              level.configValue.toLowerCase() !==
              ROLE_CONFIGURATION_CONSTANTS.GLOBAL.toLowerCase()
          );
        }
        return (rolesDropDownData && rolesDropDownData.level) || [];
      case 1:
        return (rolesDropDownData && secondDropdownOptions) || [];
      case 2:
        return (rolesDropDownData && rolesDropDownData.permission) || [];
      case 3:
        return (rolesDropDownData && rolesDropDownData.LandingPage) || [];
      case 4:
        return (rolesDropDownData && rolesDropDownData.businessFunctions) || [];
      default:
        return (rolesDropDownData && rolesDropDownData.level) || [];
    }
  };

  const updateTableCellValuesForRow = (rowID, label, cellValue) => {
    let modifiedTabelData = tableData;
    //  Get constant Ids
    const globalLevelId = levelMap[ROLE_CONFIGURATION_CONSTANTS.GLOBAL]?.id;
    const globeLandingPageId = rolesDropDownData.LandingPage.find(
      (page) => page.configValue == ROLE_CONFIGURATION_CONSTANTS.GLOBE
    )?.id;

    modifiedTabelData = modifiedTabelData.map((row) => {
      // SPECIAL CONDITION : ON SELECT OF 'Global' AT LEVEL DROPDOWN
      let disabledValue;
      if (label === ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[0]) {
        disabledValue = cellValue === globalLevelId;
      } else {
        disabledValue = row.level === globalLevelId;
      }

      if (row.rowID === rowID) {
        if (label === ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[0]) {
          if (cellValue === globalLevelId) {
            return {
              ...row,
              [label]: cellValue,
              disabled: true,
              landingPage: globeLandingPageId,
            };
          }
          return {
            ...row,
            [label]: cellValue,
            disabled: disabledValue,
          };
        }
        return {
          ...row,
          [label]: cellValue,
          disabled: disabledValue,
        };
      }
      return row;
    });
    setTableData(modifiedTabelData);
  };

  // To add a new empty row from rolelist
  const addNewRole = () => {
    const currentTableState = [...tableData];
    currentTableState.push({
      rowID: tableData.length,
      level: null,
      businessUnit: null,
      permission: null,
      landingPage: null,
      disabled: false,
      roleId: null,
      isCreated: true,
      isModified: false,
      isDeleted: false,
    });
    setTableData(currentTableState);
  };

  // To delete a row from role list
  const deleteRole = (rowID) => {
    let currentTableState = [...tableData];
    let objIndex = currentTableState.findIndex((obj) => obj.rowID === rowID);
    if (currentTableState[objIndex].roleId) {
      currentTableState[objIndex].isDeleted = true;
      currentTableState[objIndex].isModified = false;
      currentTableState[objIndex].isCreated = false;
    } else {
      currentTableState.splice(rowID, 1);
      currentTableState = currentTableState.map((row, index) => ({
        ...row,
        rowID: index,
      }));
    }
    setTableData(currentTableState);
  };

  // Data Validation before Submit
  const isDataValidated = (tableDataSet) => {
    const isValidated = [];
    tableDataSet.associatedRoles.forEach((role) => {
      isValidated.push(
        Boolean(role.levelId)
        && (levelMap[ROLE_CONFIGURATION_CONSTANTS.GLOBAL]?.id === role.levelId
            || (levelMap[ROLE_CONFIGURATION_CONSTANTS.PLANT]?.id === role.levelId && Boolean(role.plantId))
            || (levelMap[ROLE_CONFIGURATION_CONSTANTS.BU]?.id === role.levelId && Boolean(role.businessUnitId))
            )
        && Boolean(role.permissionId)
        && Boolean(role.landingPageId)
      );
    });
    return !isValidated.includes(false);
  };

  // Form Submit Handler
  const onSubmit = (formData) => {
    setIsSubmitButtonClicked(true);

    let dataToSend = {
      roleName: formData.roleName,
      description: formData.description || '',
      businessFunctionId: formData.businessFunction,
    };

    if (isEdit && searchResult) {
      // Attaching Flags
      const isOutModified =
        searchResult.roleName != formData.roleName ||
        searchResult.description != formData.description ||
        searchResult.businessFunction != formData.businessFunction;
      dataToSend['isOutModified'] = isOutModified;
      dataToSend['roleId'] = formData.roleId;

      let roleMap = {};
      searchResult.associatedRoles
        .filter((row) => row.roleId)
        .forEach((role) => {
          roleMap[role.roleId] = role;
        });

      let prevResult = {};
      formData.associatedRoles = formData.associatedRoles.map((role) => {
        if (role.roleId && !role.isDeleted) {
          prevResult = roleMap[role.roleId];
          const isModified =
            role.level !== prevResult.level ||
            role.landingPage !== prevResult.landingPage ||
            role.permission !== prevResult.permissions ||
            role.businessUnit !== prevResult.subLevel;
          return { ...role, isModified: isModified };
        } else {
          return { ...role };
        }
      });
    }

    const roles = formData.associatedRoles.map((row) => {
      let newRow = {
        levelId: row.level,
        permissionId: row.permission,
        landingPageId: row.landingPage,
        roleId: row.roleId,
        isCreated: row.isCreated,
        isModified: row.isModified,
        isDeleted: row.isDeleted,
      };

      const businessUnitLevelId = levelMap[ROLE_CONFIGURATION_CONSTANTS.BU]?.id;
      const plantLevelId = levelMap[ROLE_CONFIGURATION_CONSTANTS.PLANT]?.id;

      if (row.level == businessUnitLevelId) {
        newRow['businessUnitId'] = row.businessUnit;
      }
      if (row.level == plantLevelId) {
        newRow['plantId'] = row.businessUnit;
      }

      return newRow;
    });

    dataToSend['associatedRoles'] = roles;

    if (dataToSend.associatedRoles.length > 0 && isDataValidated(dataToSend) === true) {
      if (isEdit) {
        updateRoleMutate(dataToSend);
      } else {
        createRoleMutate(dataToSend);
      }
    }
  };

  // Loader Captions
  function getLoaderCaption() {
    if (isgetRoleDetailsByIdFetching)
      return ROLE_CONFIGURATION_CONSTANTS.LOADER_CAPTIONS[0];
    if (iscreateRoleLoading) return ROLE_CONFIGURATION_CONSTANTS.LOADER_CAPTIONS[1];
    if (isupdateRoleLoading) return ROLE_CONFIGURATION_CONSTANTS.LOADER_CAPTIONS[2];
    if (isdeleteRoleLoading) return ROLE_CONFIGURATION_CONSTANTS.LOADER_CAPTIONS[3];
    return '';
  }

  // Disable Dependent Options in DropDown Dynamically
  const disableBusinessOptions = (businessUnit, rowID) => {
    // find the level associated with the businessUnit in the selected row using rowID
    // search the tabledata and filter the rows with the selectedLevel
    // from those rows, I'll retrieve the businessUnitNames
    // check if the businessUnit argument is included in that array -> pass true if included.else pass false
    const modifiedTabelData = tableData;
    const selectedLevel = modifiedTabelData.find((row) => row.rowID === rowID).level;
    const requiredRows = modifiedTabelData.filter((row) => row.level === selectedLevel);
    const existingBusinessUnits = requiredRows.map((item) => item.businessUnit);
    const existingPermission = requiredRows.map((item) => item.permission);

    if (selectedLevel === ROLE_CONFIGURATION_CONSTANTS.GLOBAL) {
      return existingPermission.includes(businessUnit);
    }
    return existingBusinessUnits.includes(businessUnit);
  };

  // Errors Status
  const isAnyEmptyFieldPresentInForm = Object.keys(errors).length > 0;

  // Condition to show loader
  const showLoader =
    isgetRoleDetailsByIdFetching ||
    iscreateRoleLoading ||
    isupdateRoleLoading ||
    isdeleteRoleLoading;

  return (
    <SectionGrid
      // container
      item
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
    >
      {/* Modal */}
      <Modal
        aria-labelledby="transition-modal-user-search"
        aria-describedby="transition-modal-user-search"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <ModalPaper
            container
            item
            direction="column"
            alignItems="flex-start"
            xs={4}
            spacing={2}
          >
            {/* Contents */}
            <Grid sx={{ display: 'flex', padding: '1.5em' }}>
              <Grid xs={1} item sx={{ marginRight: '0.75em',}}>
                <DeleteExclamation />
              </Grid>
              <Grid
                container
                item
                xs={10}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5">Confirm!</Typography>
                </Grid>
                <Grid item sx={{    fontWeight: 'normal',
                  fontSize: '.875em',
                  lineHeight: '1.188em',
                  marginBottom: '2.188em',
                  marginTop: '1em'}}>
                  {USER_CONFIG_MESSAGES.DELETE_ROLE_DESCRIPTION_1}
                </Grid>
                <Grid item sx={{ fontWeight: 'bold', fontSize: '.875em', lineHeight: '1.188em' }}>
                  {`${USER_CONFIG_MESSAGES.DELETE_ROLE_DESCRIPTION_2}-${
                    searchResult ? searchResult.roleName : ''
                  }?`}
                </Grid>
              </Grid>
            </Grid>

            <Grid item container direction="row" justify="flex-end">
              <GeneralButton
                type={BUTTON_TYPE.SECONDARY}
                onClick={() => setModalOpen(false)}
                disabled={isdeleteRoleLoading}
              >
                {ACCESS_CONFIGURATION_CONSTANTS.DELETE_BUTTON_LABLES.NO}
              </GeneralButton>
              <GeneralButton
                onClick={() => {
                  deleteRoleMutate(searchResult.roleId);
                }}
                disabled={isdeleteRoleLoading}
              >
                {ACCESS_CONFIGURATION_CONSTANTS.DELETE_BUTTON_LABLES.YES}
              </GeneralButton>
            </Grid>
          </ModalPaper>
        </Fade>
      </Modal>
      {/* Modal update */}
      {/* Form */}
      <form sx={{ width: '100%', height: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          sx={{
            display: 'block',
            padding: '1.5em 2em 0 2em',
            color: (theme) => theme.customColors.greyishWhite,
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="h5">
                  {isEdit
                    ? ROLE_CONFIGURATION_CONSTANTS.HEADER_TITLES[0]
                    : ROLE_CONFIGURATION_CONSTANTS.HEADER_TITLES[1]}
                </Typography>
              </Grid>
              <SearchBoxGrid key="searchBar" item xs={4}>
                <SearchIconSpan component="span" m="{1}">
                  <SearchIcon />
                </SearchIconSpan>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionSelected={onSuggestionSelected}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputPropsAutoSuggest}
                  
                />
              </SearchBoxGrid>
            </Grid>
            <Grid container>
              {ROLE_CONFIGURATION_CONSTANTS.TEXT_BOX_CONSTANTS.map((item) => (
                <InputBoxGenerator
                  placeholder={item.PLACEHOLDER}
                  key={item.ID}
                  gridSize={item.GRID_SIZE}
                  label={item.LABEL}
                  type={item.TYPE}
                  id={item.ID}
                  required={item.REQUIRED}
                  pattern={item.PATTERN}
                  messageEmpty={item.MESSAGE_EMPTY}
                  messageInvalid={item.MESSAGE_INVALID}
                  maxLength={item.MAX_LENGTH}
                  control={control}
                  setOptions={setOptions}
                  watch={() => watch()}
                />
              ))}
              <Grid item xs={12} 
              sx={{
                  textAlign: 'end',
                  paddingRight: '2em',
                  marginBottom: '0.5em',
                }}>
                <Typography 
                sx={{
                  display: 'inline',
                  color:(theme) => theme.palette.background.errorColor,
                  paddingRight: '1em',
                }}>
                  {ACCESS_CONFIGURATION_CONSTANTS.ASTERIX}
                </Typography>
                <Typography variant="caption">
                  {ACCESS_CONFIGURATION_CONSTANTS.MANDATORY_FIELDS}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Table */}
          <TableGridContainer xs={12} item >
            <Table aria-label="simple table">
              <TableBody>
                {tableData
                  .filter((data) => !data.isDeleted)
                  .map((row, rowIndex) => (
                    <StyledTableRow key={row.rowID} >
                      {ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS.map(
                        (header, index) => (
                          <TableCellGenerator
                            key={header}
                            row={row}
                            label={header}
                            updateTableCellValuesForRow={updateTableCellValuesForRow}
                            index={index}
                            options={setOptions(index, row)}
                            isSubmitButtonClicked={isSubmitButtonClicked}
                            isAnyEmptyFieldPresentInForm={isAnyEmptyFieldPresentInForm}
                            disableBusinessOptions={disableBusinessOptions}
                          />
                        )
                      )}
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="subtitle2"
                          sx={{ paddingBottom: '0.75em', fontSize: '14px',}}
                        >
                          &nbsp;
                        </Typography>
                        <StyledIconButton
                          disabled={tableData.filter((data) => !data.isDeleted).length < 2}
                          aria-label="delete"
                          size="small"
                          onClick={() => deleteRole(row.rowID)}
                        >
                          <DeleteButton data-testid={`${'dlt-btn'}-${rowIndex}`}/>
                        </StyledIconButton>

                        {rowIndex ===
                        tableData.filter((data) => !data.isDeleted).length - 1 ? (
                          <IconButton
                            aria-label="add"
                            size="small"
                            onClick={addNewRole}
                            sx={{ paddingLeft: '1.25em',color:(theme)=> theme.customColors.white}}
                          >
                            <AddButton data-testid={`addRoleBtn`}/>
                          </IconButton>
                        ) : null}
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableGridContainer>
        </Grid>
        <Grid container>
          <StyledButtonContainer xs={12} item 
          // className={classes.buttonContainer}
          >
            <Grid item>
              {isEdit ? (
                <GeneralButton
                  type={BUTTON_TYPE.SECONDARY}
                  onClick={() => onReset()}
                  variant="outlined"
                  // className={classes.bottomButtons}
                >
                  {ACCESS_CONFIGURATION_CONSTANTS.BUTTON_NAME[3]}
                </GeneralButton>
              ) : (
                <GeneralButton
                  type={BUTTON_TYPE.SECONDARY}
                  disabled={disableActionButton}
                  onClick={() => onReset()}
                  variant="outlined"
                >
                  {ACCESS_CONFIGURATION_CONSTANTS.BUTTON_NAME[1]}
                </GeneralButton>
              )}
              {isEdit ? (
                <GeneralButton
                  type={BUTTON_TYPE.SECONDARY}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  variant="outlined"
                >
                  {ACCESS_CONFIGURATION_CONSTANTS.BUTTON_NAME[4]}
                </GeneralButton>
              ) : null}
              <GeneralButton
                type={BUTTON_TYPE.PRIMARY}
                variant="outlined"
                disabled={disableActionButton}
                onClick={handleSubmit(onSubmit)}
              >
                {isEdit
                  ? ACCESS_CONFIGURATION_CONSTANTS.BUTTON_NAME[2]
                  : ACCESS_CONFIGURATION_CONSTANTS.BUTTON_NAME[0]}
              </GeneralButton>
            </Grid>
            {showLoader && (
              <Grid item sx={{ display: 'flex',alignItems: 'center' }}>
                <CircularProgress size="1em" color="inherit" />
                <Typography variant="subtitle1" sx={{ paddingLeft: '1em',paddingRight: '0.5em' }}>
                  {getLoaderCaption()}
                </Typography>
              </Grid>
            )}
          </StyledButtonContainer>
        </Grid>
      </form>
    </SectionGrid>
  );
};

RoleConfigContainer.propTypes = {};

export default RoleConfigContainer;

InputBoxGenerator.propTypes = {
  placeholder: PropTypes.string,
  gridSize: PropTypes.number,
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  pattern: PropTypes.instanceOf(RegExp),
  messageEmpty: PropTypes.string,
  messageInvalid: PropTypes.string,
  control: PropTypes.shape(),
  setOptions: PropTypes.func,
  watch: PropTypes.func,
};

TableCellGenerator.propTypes = {
  row: PropTypes.object,
  // .PropTypes.shape({
  //   rowID: PropTypes.number,
  //   level: PropTypes.string,
  //   businessUnit: PropTypes.string,
  //   permission: PropTypes.string,
  //   landingPage: PropTypes.string,
  //   disabled: PropTypes.bool,
  //   roleId: PropTypes.number,
  //   isCreated : PropTypes.bool,
  //   isModified : PropTypes.bool,
  //   isDeleted : PropTypes.bool
  // }),
  label: PropTypes.string,
  updateTableCellValuesForRow: PropTypes.func,
  index: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.object),
  isSubmitButtonClicked: PropTypes.bool,
  isAnyEmptyFieldPresentInForm: PropTypes.bool,
  disableBusinessOptions: PropTypes.func,
};
